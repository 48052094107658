import React from "react"
import { ThemeProvider } from "styled-components"

import { MobileProvider } from "./src/providers/mobile/mobile.provider"
import { ModalProvider } from "./src/providers/modal/modal.provider"
import { AlertBannerProvider } from "./src/providers/alert-banner/alert-banner.provider"
import { PinnedCTAProvider } from "./src/providers/pinnedCTA/pinnedCTA.provider"
import { ShortPathProvider } from "./src/providers/short-path/short-path.provider"

import Modal from "./src/components/modal/modal.component"

import "./src/layouts/page/base.css"
import theme from "./src/themes/theme"
import "@fontsource/barlow" // Defaults to weight 400.
import "@fontsource/barlow/300.css" // Weight 300.
import "@fontsource/barlow/600.css" // Weight 600.
import "@fontsource/barlow/700.css" // Weight 700.
import "@fontsource/barlow/600-italic.css" // Weight 600 // Font-Style Italic
import { BlogSidebarProvider } from "./src/providers/blog-sidebar/blog-sidebar.provide"

require("prismjs/themes/prism-tomorrow.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")

export const onClientEntry = async () => {
  if (!(`IntersectionObserver` in window)) {
    await import(`intersection-observer`)
  }
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <React.Fragment>
      {element}
      <Modal />
    </React.Fragment>
  )
}

// Injecting third-party scripts in onInitialClientRender
export const onInitialClientRender = () => {
  // Ensure the dataLayer exists
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: "gtm.js",
    "gtm.start": new Date().getTime(),
  })

  // Google Tag Manager
  const gtmScript = document.createElement("script")
  gtmScript.async = true
  gtmScript.src = "https://www.googletagmanager.com/gtm.js?id=GTM-MZCLGNR"
  document.body.appendChild(gtmScript)

  // Unbounce script
  const ubembedScript = document.createElement("script")
  ubembedScript.src = "https://a44cc6ffd004438ab74297783b3e5fc9.js.ubembed.com"
  ubembedScript.defer = true
  ubembedScript.referrerPolicy = "origin"
  document.body.appendChild(ubembedScript)

  // Gtag function
  const gtagScript = document.createElement("script")
  gtagScript.async = true
  gtagScript.innerHTML = `
    function gtag() { dataLayer.push(arguments); }
  `
  document.body.appendChild(gtagScript)
}

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <MobileProvider>
          <PinnedCTAProvider>
            <ShortPathProvider>
              <BlogSidebarProvider>
                <AlertBannerProvider>{element}</AlertBannerProvider>
              </BlogSidebarProvider>
            </ShortPathProvider>
          </PinnedCTAProvider>
        </MobileProvider>
      </ModalProvider>
    </ThemeProvider>
  )
}
